import './manage-pixel.css'
import {
  Page,
  InlineStack,
  Button,
  IndexTable,
  Badge,
  Text,
  ButtonGroup,
  Icon,
  Pagination,
  Spinner,
  Frame,
  Modal,
  Toast
} from "@shopify/polaris";
import {useCallback, useEffect, useState } from 'react';
import type { Dispatch, SetStateAction, ReactElement } from "react";
import { useNavigate } from 'react-router-dom';
import Navigation from "../../components/navigation/Navigation";
import {
  FraudProtectMinor
} from '@shopify/polaris-icons';
import AppleIcon from '../../assets/svg/apple.svg'
import { DeletePixelRequest, GetPixelList } from '../../api/manage-pixel';
import type { PixelDetail } from '../../dto/pixel.dto';
import { EVENT_API_TARGET } from '../../dto';
import config from '../../config';
  
  export default function ManagePixel() {
    const navigate = useNavigate()
    const [pixels, setPixels] = useState<PixelDetail[]>([]);
    const [loading, setLoading] = useState<boolean>(true);
    const [openModalConfirmDelete, setOpenModalConfirmDelete] = useState<boolean>(false)
    const [pixelToBeDelete, setPixelToBeDelete] = useState<PixelDetail | undefined>(undefined)
    const [alert, setAlert] = useState<ReactElement | null>(null)

    useEffect(() => {
      fetchPixelList()
    }, [])

    const fetchPixelList = async () => {
      const data = await GetPixelList();
      setPixels(data)

      setTimeout(() => {
        setLoading(false)
      }, 500)
    }

    const renderTargetLabel = (target: EVENT_API_TARGET) => {
      if (target === EVENT_API_TARGET.ENTIRE_STORE) {
        return 'All Pages'
      }
      if (target === EVENT_API_TARGET.PRODUCTS) {
        return 'Product'
      }
      if (target === EVENT_API_TARGET.COLLECTIONS) {
        return 'Collection'
      }
    }

    const rowMarkup = pixels.map((pixel,index) => {
      const {FacebookPixel, Title, IsEnableConversationAPI, Target, Status, ID} = pixel
      return <IndexTable.Row id={String(ID)} key={ID} position={index}>
        <IndexTable.Cell>
          <Text variant="bodyMd" fontWeight="regular" as="span">
            <p style={{color: 'var(--p-color-bg-fill-info-active)'}}>
              {Title} - {FacebookPixel}
            </p>
          </Text>
        </IndexTable.Cell>
        <IndexTable.Cell>
          <InlineStack align="end" blockAlign="center">
            {renderTargetLabel(Target as EVENT_API_TARGET)}
          </InlineStack>
        </IndexTable.Cell>
        <IndexTable.Cell>
          <InlineStack align="end" blockAlign="center">
            {IsEnableConversationAPI ? 
              <div className='eventapi-badge'>
                <img src={AppleIcon} alt="appleIcon" />
                Event API</div> 
              : 
              <div className='browserpixel-badge'>
                <Icon
                  source={FraudProtectMinor}
                  tone="base"
                />
                Browser Pixel</div>
            }
          </InlineStack>
        </IndexTable.Cell>
        <IndexTable.Cell>
          <InlineStack align="end" blockAlign="center">
            {Status ? <Badge tone="success">Active</Badge> : <Badge>Disabled</Badge>}
          </InlineStack>
        </IndexTable.Cell>
        <IndexTable.Cell>
          <InlineStack align="end" blockAlign="center">
            <ButtonGroup>
              <Button onClick={() => navigate(`/pixel/manage-pixel/${ID}?shop=${config.shop}&admin=${config.admin}&host=${config.host}`)}>Edit</Button>
              <Button 
                variant="primary" 
                tone="critical"
                onClick={() => {
                  setPixelToBeDelete(pixel)
                  setOpenModalConfirmDelete(true)
                }}
              >
                Delete
              </Button>
            </ButtonGroup>
          </InlineStack>
        </IndexTable.Cell>
      </IndexTable.Row>
      },
    );

    const resourceName = {
      singular: 'pixel',
      plural: 'pixels',
    };

    return (
      <Page fullWidth>
        <Navigation />
        <div className='manage-pixel p-2'>
          <div className="my-2">
            <InlineStack align="end" blockAlign="center">
              <Button variant="primary" onClick={() => navigate('/pixel/create-pixel')}>
                Add Tiktok Pixel
              </Button>
            </InlineStack>
          </div>
          <div className="managepixel-table p-2">
            {loading ?
            <Skeleton />
            :
            <IndexTable
            resourceName={resourceName}
            itemCount={pixels.length}
            headings={[
              {title: 'Tiktok Pixel'},
              {title: 'Target', alignment: 'end'},
              {title: 'Events API', alignment: 'end'},
              {title: 'Status', alignment: 'end'},
              {title: 'Action', alignment: 'end'},
            ]}
              selectable={false}
            >
              {rowMarkup}
            </IndexTable>
            }
            <div className="my-2">
              <InlineStack align='end'>
                <Pagination
                  hasPrevious
                  onPrevious={() => {
                    console.log('Previous');
                  }}
                  hasNext
                  onNext={() => {
                    console.log('Next');
                  }}
                />
              </InlineStack>
            </div>
          </div>
        </div>
        <ConfirmDeletePixelModal 
          active={openModalConfirmDelete}
          setActive={setOpenModalConfirmDelete}
          pixelToBeDelete={pixelToBeDelete}
          alert={alert}
          setAlert={setAlert}
          fetchPixelList={fetchPixelList}
        />
        {alert}
      </Page>
    );
  }

  function ConfirmDeletePixelModal(props: 
    {
      active: boolean,
      setActive: Dispatch<SetStateAction<boolean>>,
      pixelToBeDelete?: PixelDetail;
      alert: ReactElement | null;
      setAlert: Dispatch<SetStateAction<ReactElement | null>>;
      fetchPixelList: () => void
    }) {
      

    // eslint-disable-next-line react-hooks/exhaustive-deps
    const handleChange = useCallback(() => props.setActive(!props.active), [props.active]);
  
    const submitDeletePixel = async () => {
      if (props.pixelToBeDelete) {
        const {ID, ShopID} = props.pixelToBeDelete
        const response = await DeletePixelRequest({ID,ShopID});
        props.setActive(false)
        if (response?.data?.IsSuccess) {
          props.fetchPixelList()
          props.setAlert(
            <Frame>
              <Toast
                content={
                  "The pixel ID: " +
                  props.pixelToBeDelete.Title +
                  " deleted successfully"
                }
                onDismiss={() => props.setAlert(null)}
              />
            </Frame>
          )
        } else {
          props.setAlert(
          <Frame>
            <Toast
              content={response?.data?.Messenger}
              onDismiss={() => props.setAlert(null)}
            />
          </Frame>
          )
        }
      }
    }

    return (
      <div>
        <Frame>
          <Modal
            open={props.active}
            onClose={handleChange}
            title="Delete Facebook Pixel"
            primaryAction={{
              content: 'OK',
              onAction: submitDeletePixel,
            }}
            secondaryActions={[
              {
                content: 'Cancel',
                onAction: handleChange,
              },
            ]}
          >
            <Modal.Section>
              <p>
                Do you want to delete {props.pixelToBeDelete?.Title} pixel?
              </p>
            </Modal.Section>
          </Modal>
        </Frame>
      </div>
    );
  }

  function Skeleton() {
    return (
      <div className='loading-manage-pixel'>
        <Spinner accessibilityLabel="Spinner example" size="large" />
      </div>
    );
  }
