import config from "../../config";
import "./Navigation.css";
import { useNavigate } from "react-router-dom";

interface NavigationItem {
  path: string;
  absPath: string;
  label: string;
}

const navigationList: NavigationItem[] = [
  {
    path: `/pixel/dashboard?shop=${config.shop}&admin=${config.admin}&host=${config.host}`,
    absPath: "/pixel/dashboard",
    label: "Dashboard",
  },
  {
    path: `/pixel/create-pixel?shop=${config.shop}&admin=${config.admin}&host=${config.host}`,
    absPath: "/pixel/create-pixel",
    label: "Create Pixel",
  },
  {
    path: `/pixel/manage-pixel?shop=${config.shop}&admin=${config.admin}&host=${config.host}`,
    absPath: "/pixel/manage-pixel",
    label: "Manage Pixel",
  },
  {
    path: `/pixel/tiktok-shop?shop=${config.shop}&admin=${config.admin}&host=${config.host}`,
    absPath: "/pixel/tiktok-shop",
    label: "Tiktok Shop",
  },
  {
    path: `/pixel/facebook-pixels?shop=${config.shop}&admin=${config.admin}&host=${config.host}`,
    absPath: "/pixel/facebook-pixels",
    label: "Facebook Pixels",
  },
];

export default function Navigation() {
  const navigate = useNavigate();
  const checkActiveLink = (currentPath: string, linkPath: string) => {
    return currentPath.includes(linkPath) ? true : false;
  };
  const handleNavigate = (path: string, absPath: string) => {
    if (absPath == "/pixel/facebook-pixels") {
      window.open(
        "https://apps.shopify.com/yuri-facebook-multi-pixels?surface_detail=tiktok&surface_type=ref",
        "_blank"
      );
    } else {
      navigate(path);
    }
  };

  return (
    <ul className="navigation">
      {navigationList.map((navigation) => {
        return (
          <li
            className={
              checkActiveLink(window.location.pathname, navigation.absPath)
                ? "active"
                : ""
            }
            key={navigation.label}
            onClick={() => handleNavigate(navigation.path, navigation.absPath)}
          >
            <p>{navigation.label}</p>
          </li>
        );
      })}
    </ul>
  );
}
