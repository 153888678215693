import "./App.css";
import { useState } from "react";
import { RouterProvider } from "react-router-dom";
import { useEffect } from "react";
import { getAppData } from "./api/initapp";
import config from "./config";
import { GetPixelList } from "./api/manage-pixel";
import { createBrowserRouter, Navigate } from "react-router-dom";
import Dashboard from "./pages/dashboard/dashboard";
import CreatePixel from "./pages/create-pixel/create-pixel";
import ManagePixel from "./pages/manage-pixel/manage-pixel";
import PixelDetail from "./pages/manage-pixel/pixel-detail";
import PluginChat from "./config/pluginChat";
import TiktokShop from "./pages/tiktok-shop/tiktok-shop";

function App() {
  const [routers, setRouters] = useState<any>([]);
  const [loadInit, setLoadInit] = useState<boolean>(true);

  useEffect(() => {
    initAppData();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const initAppData = async () => {
    setLoadInit(true);
    await getAppData(config.shop);
    await fetchPixelList();
    PluginChat.loadGoogleScript();
    PluginChat.loadChatPlugin(config.shop);
    PluginChat.loadChatPluginBackup(config.shop);
    setLoadInit(false);
  };

  const fetchPixelList = async () => {
    const res = await GetPixelList();
    setRouters(
      createBrowserRouter([
        {
          path: "/pixel/dashboard",
          element: <Dashboard />,
        },
        {
          path: "/pixel/create-pixel",
          element: <CreatePixel />,
        },
        {
          path: "/pixel/manage-pixel",
          element: <ManagePixel />,
        },
        {
          path: "/pixel/manage-pixel/:id",
          element: <PixelDetail />,
        },
        {
          path: "/pixel/tiktok-shop",
          element: <TiktokShop />,
        },
        {
          path: "*",
          element: (
            <Navigate
              to={`/pixel/${res?.length ? "manage-pixel" : "create-pixel"}`}
            />
          ),
        },
      ])
    );
  };

  if (loadInit) {
    return <></>;
  } else {
    return <RouterProvider router={routers} />;
  }
}

export default App;
