import {
    BlockStack,
    Card,
    Grid,
    Page,
    Text,
    Button,
    ButtonGroup,
    Link,
    Checkbox,
    InlineStack,
  } from "@shopify/polaris";
  import { useState } from "react";
  import type { Dispatch, SetStateAction } from "react";
  import Progress from "../../components/progress/Progress";
  import Toturial from "../../components/toturial/Toturial";
  import { PROGRESS, type ToturialDto } from "../../dto";
  import CopyToClipboard from "react-copy-to-clipboard";
  import Copy from '../../assets/svg/copy';
  import ThumnailImg from '../../assets/img/toturial-thumbnail.png';

  const step3Toturial : ToturialDto[] = [
    {
    index: 1,
    title: 'How to add the script to check out ',
    des: 'Copy the code below and paste it into the Settings > Checkout > additional scripts f',
    thumbnailUrl: ThumnailImg,
    link: 'https://www.youtube.com/watch?v=lr7rPLDK4m4',
    vidLength: 80,
   }
  ]

  interface Step3Props {
    progress: number;
    setProgress: Dispatch<SetStateAction<PROGRESS>>
    submitCreatePixel: () => void
  }
  
  export default function Step3(props: Step3Props) {
    const [isCheck, setIsCheck] = useState<boolean>(false);

    const submitStep3 = () => {
      props.submitCreatePixel()
      props.setProgress(PROGRESS._100)
    }
    
    return (
      <div className="step-3">
        <Page fullWidth>
          <Grid>
            <Grid.Cell columnSpan={{xs: 6, sm: 6, md: 2, lg: 4, xl: 4}}>
              <Progress progress={props.progress}/>
              <Toturial 
                toturials={step3Toturial}
              />
            </Grid.Cell>
            <Grid.Cell columnSpan={{xs: 6, sm: 6, md: 4, lg: 8, xl: 8}}>
              <Card >
                <Text as="h2" variant="headingLg">Step 3: Add the script to the Settings {"->"} Checkout</Text>
                <div className="py-3">
                  <Text as="p">
                    Copy the code below and paste it into the{" "}
                    <span style={{color: 'var(--p-color-text-link)', fontWeight: '500'}}>
                      <Link 
                        monochrome
                        removeUnderline
                        target="_blank"
                        url={`https://${localStorage.getItem('shop')}/admin/settings/checkout`}
                      >
                        Settings {"->"} Checkout {"->"} additional
                      </Link>
                    </span>
                    {" "} scripts field.
                  </Text>
                </div>
                <div className="copy-script-box">
                  <div className="copy-script-btn">
                    <CopyToClipboard
                      text={`<script type="text/javascript" src="${process.env.REACT_APP_END_POINT}/Content/madgictiktokevent.min.js"></script>`}
                    >
                      <div
                        onClick={() => {}}
                      >
                        <Copy />
                      </div>
                    </CopyToClipboard>
                  </div>
                  <Text as="p" fontWeight="medium">{`<script type="text/javascript" src="${process.env.REACT_APP_END_POINT}/Content/madgictiktokevent.min.js"></script>`}</Text>
                </div>
                <InlineStack blockAlign="center">
                  <Checkbox label="I've done it" id="checkbox" name="checkbox" checked={isCheck} onChange={() => setIsCheck(!isCheck)} />
                </InlineStack>
                <div className="py-3">
                  <InlineStack blockAlign="end" align="space-between">
                    <Button 
                      variant="plain"
                      onClick={() => submitStep3()}
                    >
                      I'll do it later
                    </Button>
                    <ButtonGroup>
                      <Button onClick={() => props.setProgress(PROGRESS._25)}>Back</Button>
                      <Button 
                        variant="primary" 
                        onClick={() => submitStep3()}
                        disabled={!isCheck}
                      >
                        Next Step
                      </Button>
                    </ButtonGroup>
                  </InlineStack>
                </div>
              </Card>
            </Grid.Cell>
          </Grid>
        </Page>
      </div>
    )
  }
  
  