const PluginChat = {
  loadChatPlugin: (shop: any) => {
    const script2 = document.createElement("script");
    script2.innerHTML = `
        window.$crisp=[];window.CRISP_WEBSITE_ID="ccb8cd20-fdee-4b66-a871-2de4a1408d0a";CRISP_TOKEN_ID = btoa("${shop}");
        (function(){d=document;s=d.createElement("script");s.src="https://client.crisp.chat/l.js";s.async=1;d.getElementsByTagName("head")[0].appendChild(s);})();
        $crisp.push(["set", "user:nickname", ["${shop}"]]);
        $crisp.push(["set", "session:segments", [["TiktokEvent"]]]);`;
    document.body.appendChild(script2);
  },
  loadChatPluginBackup: (shop: any) => {
    // const script2 = document.createElement("script");
    // script2.innerHTML = `
    // setTimeout(function(){if(!document.getElementById('crisp-chatbox')) {d=document;s=d.createElement("script");s.src="https://code.tidio.co/rvxustxuoq2e0mgcep1x1zrt3ynxmkhi.js";s.async=1;d.getElementsByTagName("head")[0].appendChild(s); setTimeout(function () {tidioChatApi.setVisitorData({distinct_id: btoa("` + shop + `"), name: "` + shop + `", tags : ["FacebookPixel"] });}, 1000); }},5000);`;
    // document.body.appendChild(script2);
  },
  openChat: () => {
    const script2 = document.createElement("script");
    script2.innerHTML = `
        $crisp.push(['do', 'chat:open']);`;
    document.body.appendChild(script2);
  },
  triggerEvent: (eventName: any) => {
    const script2 = document.createElement("script");
    script2.innerHTML = `
        $crisp.push(["set", "session:event", ["${eventName}"]]);`;
    document.body.appendChild(script2);
  },
  loadGoogleScript: () => {
    const script = document.createElement("script");
    script.src = "https://www.googletagmanager.com/gtag/js?id=UA-146802944-1";
    script.async = true;
    document.body.appendChild(script);

    const script2 = document.createElement("script");
    script2.innerHTML = ` window.dataLayer = window.dataLayer || [];
    function gtag() { dataLayer.push(arguments); }
    gtag('js', new Date());
    gtag('config', 'UA-146802944-1');`;
    document.body.appendChild(script2);
  },
};

export default PluginChat;
