import React from 'react';
import ReactDOM from 'react-dom/client';
import './index.css';
import '@shopify/polaris/build/esm/styles.css';

import { Provider as ProviderAppBridge } from "@shopify/app-bridge-react";
import translations from "@shopify/polaris/locales/en.json";
import { AppProvider } from "@shopify/polaris";
import { OTGetUrlParameter } from './utils';
import App from './App';

const configApp = {
  apiKey: process.env.REACT_APP_API_KEY || "",
  host: OTGetUrlParameter("host"),
  forceRedirect: false,
};

const root = ReactDOM.createRoot(
  document.getElementById('root') as HTMLElement
);
root.render(
  <AppProvider i18n={translations}>
    <ProviderAppBridge config={configApp}>
      <React.StrictMode>
        <App />
      </React.StrictMode>
    </ProviderAppBridge>
  </AppProvider>
);

